import { useApolloClient } from "@vue/apollo-composable";
import { globals } from "@/core/globals";
import opGetQuickConnectDetailsQueryDocument from "./opusGetQuickConnectDetailsQuery.graphql";
import type { Query, QueryQuoteArgs, OpusQuoteType } from "@/core/api/graphql/types";

export async function opGetQuickConnectDetails(payload?: QueryQuoteArgs): Promise<OpusQuoteType> {
  const { storeId, userId, cultureName, currencyCode } = globals;
  const { client } = useApolloClient();
  const { data } = await client.query<Required<Pick<Query, "quote">>, QueryQuoteArgs>({
    query: opGetQuickConnectDetailsQueryDocument,
    variables: {
      storeId,
      userId,
      ...payload,
    },
  });

  return data.quote;
}
