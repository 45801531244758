<template>
  <VcWidget :title="$t('shared.opus.quick_connect.items_section.title')" prepend-icon="cube" size="lg">
    <div class="flex flex-col gap-5 lg:gap-8">
      <div
        :class="{ 'mt-5 ': !readonly }"
        class="flex flex-col space-y-5 md:flex-row md:items-end md:justify-center md:space-x-3 md:space-y-0"
      >
        <div class="flex w-full grow items-end space-x-3">
          <VcInput
            v-if="!readonly"
            v-model.trim="product.name"
            :label="$t('shared.opus.quick_connect.items_section.product_label')"
            maxlength="256"
            class="w-full"
            :placeholder="$t('shared.opus.quick_connect.items_section.product_placeholder')"
          />
        </div>
        <div class="flex grow items-end space-x-3">
          <div v-if="!readonly" class="flex w-full grow justify-center">
            <OpQuantity
              :key="key"
              v-model="product.quantity"
              size="md"
              type="number"
              additional-classes="w-full flex grow"
              center
              disable-timeout
              :label="$t('common.labels.quantity')"
              :placeholder="$t('shared.opus.quick_connect.items_section.product_quantity_placeholder')"
              :min-quantity="1"
              :limitation="quantityLimit"
              @trigger="(value?: number) => quantityHandler(value)"
            />
          </div>
          <VcButton
            v-if="!readonly"
            icon="plus-1"
            variant="outline"
            size="md"
            class="items-center text-center"
            @click="product.name && product.quantity && addProduct()"
          />
        </div>
      </div>
      <div
        v-if="products.length"
        class="flex flex-col bg-additional-50 shadow-sm lg:rounded"
        :class="{ 'lg:border': products.length, 'rounded-sm border border-neutral-200': isMobile }"
      >
        <VcTable
          class="border-red-800' flex flex-col bg-additional-50 shadow-sm lg:rounded"
          :pages="pages"
          :page="page"
          layout="table-auto"
          :columns="columns"
          :items="paginatedProducts"
          :hide-default-footer="false"
          @page-changed="onPageChange"
        >
          <template #header>
            <thead class="border-b border-neutral-200">
              <tr>
                <th class="flex w-2/3 flex-row px-5 py-3 text-left font-extrabold">
                  <!-- {{ $t("shared.opus.quick_connect.items_section.table.product") }} -->
                  {{ $t("shared.opus.quick_connect.items_section.table.product") }}
                </th>
                <th class="w-1/6 px-5 py-3 text-left font-extrabold">
                  <span>
                    {{ $t("shared.opus.quick_connect.items_section.table.sku") }}
                  </span>
                </th>
                <th class="w-1/6 px-5 py-3 text-center font-extrabold">
                  <span>{{ $t("shared.opus.quick_connect.items_section.table.quantity") }}</span>
                </th>
                <th class="w-2 px-5 py-3 text-left"></th>
              </tr>
            </thead>
          </template>

          <template #desktop-body>
            <tr
              v-for="(_product, index) in paginatedProducts"
              :key="index"
              :class="{ 'border-b border-neutral-200': index !== products.length - 1 }"
              class="even:bg-neutral-50"
            >
              <td class="overflow-hidden text-ellipsis p-5 font-bold">
                <span v-html-safe="replaceUnicode(_product.name)" />
              </td>

              <td class="overflow-hidden text-ellipsis p-5 font-normal">
                <span v-if="_product?.sku">
                  {{ _product.sku }}
                </span>
              </td>
              <td class="w-1 items-center overflow-hidden p-3 text-center">
                <div class="flex justify-center">
                  <OpQuantity
                    :key="key"
                    v-model="_product.quantity"
                    :disabled="readonly"
                    class="w-[5.625rem]"
                    :min-quantity="1"
                    :limitation="quantityLimit"
                    disable-timeout
                    @trigger="(value?: number) => quantityHandler(value)"
                  />
                </div>
              </td>
              <td class="items-center overflow-hidden p-3 text-center">
                <button
                  type="button"
                  tabindex="0"
                  :class="{ 'cursor-pointer': _product.removable }"
                  @click="_product.removable && removeItem(_product)"
                  @keydown.enter="_product.removable && removeItem(_product)"
                >
                  <VcIcon
                    v-if="!readonly"
                    size="xs"
                    name="delete-thin"
                    :class="{ 'fill-neutral': !_product.removable }"
                  />
                </button>
              </td>
            </tr>
          </template>

          <template #mobile-item="itemObject">
            <div class="flex flex-col border-b border-b-neutral-200 bg-additional-50 shadow-sm">
              <div class="flex flex-row items-start justify-between overflow-hidden text-ellipsis">
                <span class="px-5 pb-2 pt-5 md:pb-5">{{ itemObject.item?.name }}</span>

                <button
                  v-if="!readonly"
                  type="button"
                  tabindex="0"
                  class="ml-auto flex items-center px-3 pb-1 pt-6 md:pb-3"
                  @click="itemObject.item.removable && removeItem(itemObject.item)"
                  @keydown.enter="itemObject.item.removable && removeItem(itemObject.item)"
                >
                  <VcIcon center size="xs" name="delete-thin" :class="{ 'fill-neutral': !itemObject.item.removable }" />
                </button>
              </div>
              <div class="flex flex-row items-center overflow-hidden text-ellipsis px-5">
                <div class="ml-0 w-1/6 py-3 text-left text-base font-medium text-neutral-500">
                  {{ $t("shared.opus.quick_connect.items_section.table.sku") }}
                </div>
                <span v-if="itemObject.item?.sku" class="ml-auto text-ellipsis">
                  {{ itemObject.item.sku }}
                </span>
              </div>
              <div class="mr-auto overflow-hidden p-3 text-center">
                <OpQuantity
                  :key="key"
                  v-model="itemObject.item.quantity"
                  center
                  :disabled="readonly"
                  size="md"
                  :limitation="quantityLimit"
                  :min-quantity="1"
                  additional-classes="!w-48"
                  @trigger="(value?: number) => quantityHandler(value)"
                />
              </div>
            </div>
          </template>

          <template #desktop-skeleton>
            <tr v-for="i in itemsPerPage" :key="i" class="even:bg-neutral-50">
              <td v-for="column in columns.length" :key="column" class="px-5 py-4">
                <div class="h-5 animate-pulse bg-neutral-200" />
              </td>
            </tr>
          </template>
        </VcTable>
      </div>
      <div class="flex flex-row items-center">
        <VcTextarea
          v-if="readonly && extraNotes"
          :model-value="extraNotes"
          :placeholder="$t('shared.opus.quick_connect.items_section.comment_placeholder')"
          :label="$t('shared.opus.quick_connect.items_section.comments')"
          required
          class="block w-full"
          :disabled="readonly"
          :rows="isMobile ? 7 : 3"
        />

        <VcTextarea
          v-if="!readonly"
          v-model.trim="notes"
          required
          :placeholder="$t('shared.opus.quick_connect.items_section.comment_placeholder')"
          :label="$t('shared.opus.quick_connect.items_section.comments')"
          class="block w-full"
          :disabled="readonly"
          :max-length="1000"
          :rows="isMobile ? 7 : 3"
          counter
        />
      </div>
    </div>
  </VcWidget>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { without } from "lodash";
import { computed, ref, watch, onMounted } from "vue";
import { replaceUnicode } from "@/core/utilities";
import { RequestType } from "@/shared/opus/quick-connect/types";
import type { ShortProductType, ITemsAndNotesPayload } from "@/shared/opus/quick-connect/types";
import OpQuantity from "@/shared/opus/common/components/op-quantity.vue";

interface IProps {
  requestType?: RequestType;
  items?: ShortProductType[];
  readonly?: boolean;
  extraNotes?: string;
}

interface IEmits {
  (event: "change:itemsAndNotes", value: ITemsAndNotesPayload): ITemsAndNotesPayload;
}

const emit = defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  requestType: RequestType.BulkQuote,
});

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");
const page = ref(1);
const itemsPerPage = ref(5);
const quantityLimit = 99999;

const products = ref<ShortProductType[]>([]);
const notes = ref("");
const key = ref(Math.random());

const product = ref<ShortProductType>({ name: "", quantity: 1, removable: true });

const pages = computed(() => Math.ceil(products.value.length / itemsPerPage.value));
const paginatedProducts = computed<ShortProductType[]>(() =>
  products.value.slice((page.value - 1) * itemsPerPage.value, page.value * itemsPerPage.value),
);

function addProduct() {
  products.value = [...products.value, { name: product.value.name, quantity: product.value.quantity, removable: true }];
  product.value = { name: "", quantity: 1, removable: true };
}

function removeItem(selectedProduct: ShortProductType) {
  products.value = without(products.value, selectedProduct);

  if (!paginatedProducts.value.length && page.value > 1) {
    page.value = --page.value;
  }
}

function quantityHandler(value?: number) {
  if (value && value >= quantityLimit) {
    key.value = Math.random();
  }
}

function onPageChange(newPage: number): void {
  page.value = newPage;
}

const columns = computed<ITableColumn[]>(() => [
  {
    id: "Product",
    title: "Product",
    classes: "w-9/12",
  },
  {
    id: "Sku",
    title: "SKU",
    classes: "w-1/4",
  },
  {
    id: "Quantity",
    title: "Quantity",
  },
  {
    id: "Actions",
    classes: "w-2",
    align: "right",
  },
]);

watch([notes, products], ([newNotes, newProducts]) => {
  emit("change:itemsAndNotes", {
    notes: newNotes,
    items: newProducts,
  });
});
onMounted(() => {
  if (props.items) {
    products.value = props.items;
  }
});
watch(
  () => props.extraNotes,
  (value) => {
    if (value) {
      notes.value = value;
    }
  },
);

watch(
  () => props.items,
  (value) => {
    if (value) {
      products.value = value;
      product.value.name = "";
    }
  },
);
</script>
