<template>
  <div class="relative flex grow items-stretch">
    <VcInput
      v-model="searchPhrase"
      :disabled="tabAccessabilityIndex == -1"
      :tabindex="tabAccessabilityIndex"
      :maxlength="MAX_LENGTH"
      class="w-full"
      :placeholder="$t('shared.opus.quick_connect.supplier_search_dialog.filters.search_placeholder')"
      @keyup.enter="applySearch()"
    >
      <template #append>
        <button
          v-if="searchPhrase"
          :tabindex="tabAccessabilityIndex"
          :disabled="tabAccessabilityIndex == -1"
          aria-label="remove keyword"
          type="button"
          class="flex h-full items-center px-2"
          @click="reset"
        >
          <VcIcon name="delete-2" size="xs" class="text-primary" />
        </button>

        <VcButton
          :aria-label="$t('shared.layout.search_bar.search_button')"
          :tabindex="tabAccessabilityIndex"
          :disabled="tabAccessabilityIndex == -1"
          title="search"
          icon="search"
          size="sm"
          @click="applySearch()"
        />
      </template>
    </VcInput>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { configInjectionKey } from "@/core/injection-keys";
import { opUseSupplierSearch } from "@/shared/opus/quick-connect/composables/opUseSupplierSearch";

const props = defineProps<IProps>();

interface IProps {
  tabAccessabilityIndex?: number | undefined;
}

const config = inject(configInjectionKey);

const MAX_LENGTH = config?.search_max_chars || 999;
const MIN_LENGTH = 0;

const { searchCriteria, page, searchSuppliersWithFacets } = opUseSupplierSearch({ withFacets: true });

const searchPhrase = ref(searchCriteria.value.query);
const trimmedSearchPhrase = computed(() => {
  return searchPhrase.value.trim();
});

function reset() {
  searchPhrase.value = "";
  searchCriteria.value.query = "";
}

async function applySearch() {
  if (trimmedSearchPhrase.value.length > MAX_LENGTH || trimmedSearchPhrase.value.length < MIN_LENGTH) {
    return;
  }

  page.value = 1;

  searchCriteria.value.query = trimmedSearchPhrase.value;

  await searchSuppliersWithFacets();
}
</script>
