import { useQuery } from "@vue/apollo-composable";
import { toValue } from "vue";
import { GetCartByIdDocument } from "@/core/api/graphql/types";
import { globals } from "@/core/globals";
import type { MaybeRefOrGetter } from "vue";

export function useGetCartByIdQuery(cartId: MaybeRefOrGetter<string>) {
  const { storeId, cultureName, currencyCode, userId } = globals;

  const variables = {
    storeId,
    cultureName,
    currencyCode,
    userId,
    cartId: toValue(cartId),
  };

  return useQuery(GetCartByIdDocument, variables);
}
