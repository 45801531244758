import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import opusGetCategoriesQueryDocument from "./opusGetCategoriesQuery.graphql";
import type { OpusCategoryConnection, Query, QueryCategoriesArgs } from "@/core/api/graphql/types";

export async function getCategories(payload?: QueryCategoriesArgs): Promise<OpusCategoryConnection> {
  const { storeId, userId, cultureName, currencyCode } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "categories">>, QueryCategoriesArgs>({
    query: opusGetCategoriesQueryDocument,
    variables: {
      userId,
      cultureName,
      currencyCode,
      storeId,
      ...payload,
    },
  });

  return data.categories;
}
