<template>
  <VcModal
    hide-actions
    :title="$t('shared.opus.welcome-registration-modal.title')"
    max-width="560px"
    is-mobile-fullscreen
    is-scrollable-on-desktop
  >
    <div class="flex flex-col gap-6 p-6 md:px-12 md:pb-12 md:pt-6">
      <div class="flex justify-center">
        <VcImage src="/static/images/opus/opus-registration-modal-logo.svg" alt="Opus LOGO" class="h-12" lazy />
      </div>

      <h2 class="text-center text-3xl font-bold">
        {{ $t("shared.opus.welcome-registration-modal.processing_registration") }}
      </h2>

      <div class="flex flex-col items-center gap-6 px-4 text-center sm:px-12">
        <span class="px-8 text-sm">
          {{ $t("shared.opus.welcome-registration-modal.email_update") }}
        </span>
        <span class="flex text-base font-bold">
          {{ $t("shared.opus.welcome-registration-modal.benefits_title") }}
        </span>
      </div>

      <div class="flex flex-col gap-6 text-left">
        <div class="flex items-start gap-3">
          <div
            class="mt-0.5 flex size-5 items-center justify-center rounded-full bg-primary text-center text-additional-50"
          >
            <span class="mt-1.5 size-5 text-xs">1</span>
          </div>
          <div class="flex flex-col gap-3">
            <div class="text-sm sm:mr-14">{{ $t("shared.opus.welcome-registration-modal.benefit_1") }}</div>
            <div class="flex flex-col justify-center gap-3 sm:mr-8 sm:flex-row">
              <VcButton :size="isMobile ? 'sm' : 'xs'">
                <a href="https://info.omniapartners.com/opus-new-user-tour" target="_blank" class="font-bold">{{
                  $t("shared.opus.welcome-registration-modal.take_tour_button")
                }}</a>
              </VcButton>
              <VcButton :size="isMobile ? 'sm' : 'xs'" variant="outline" @click="closeModal">
                <span class="font-bold">{{ $t("shared.opus.welcome-registration-modal.explore_opus_button") }}</span>
              </VcButton>
            </div>
          </div>
        </div>

        <div class="flex items-start gap-3">
          <div class="flex size-5 items-center justify-center rounded-full bg-primary text-center text-additional-50">
            <span class="mt-1.5 size-5 text-xs">2</span>
          </div>
          <div class="flex flex-col gap-3">
            <span class="text-sm">{{ $t("shared.opus.welcome-registration-modal.benefit_2") }}</span>
            <a
              href="https://www.omniapartners.com/solutions/contract-offerings"
              target="_blank"
              class="mr-auto cursor-pointer border-b border-dashed border-[--link-color] text-sm leading-[18px] text-[--link-color] hover:text-[--link-hover-color]"
            >
              {{ $t("shared.opus.welcome-registration-modal.open_contract_portfolio") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </VcModal>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useModal } from "@/shared/modal";

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");
const { closeModal } = useModal();
</script>
