import { computed } from "vue";
import { useThemeContext } from "@/core/composables/useThemeContext";
import { Logger } from "../utilities/logger";
import type { SettingsCatalogFacetType } from "@/shared/opus";

const { modulesSettings } = useThemeContext();

const MODULE_KEYS = {
  ID: "Opus",
  GOOGLE_TAG_MANAGER_ENABLED: "Opus.Analytics.GoogleTagManagerEnabled",
  GOOGLE_TAG_MANAGER_ID: "Opus.Analytics.GoogleTagManagerId",
  LINKED_IN_INSIGHT_TAG_ENABLED: "Opus.Analytics.LinkedInInsightTagEnabled",
  HUB_SPOT_TAG_ENABLED: "Opus.Analytics.HubSpotTagEnabled",
  HUB_SPOT_TAG_ACCOUNT_ID: "Opus.Analytics.HubSpotTagAccountId",
  DISABLE_TRACKING_DOMAINS: "Opus.Analytics.DisableTrackingDomains",
  NEW_QUICK_CONNECT_ENABLED: "Opus.ToggledFeatures.NewQuickConnectEnabled",
  PRICE_SORTING_ENABLED: "Opus.ToggledFeatures.PriceSortingEnabled",
  OFFERS_PRELOAD_ENABLED: "Opus.Search.OffersPreloadEnabled",
  TAX_CERTIFICATES_STORAGE_ENABLED: "Opus.ToggledFeatures.TaxCertificatesStorageEnabled",
  PREVIEW_ENABLED: "Opus.ToggledFeatures.PreviewEnabled",
  PARTIAL_ORDER_APPROVAL_REQUESTS_ENABLED: "Opus.ToggledFeatures.PartialOrderApprovalRequestsEnabled",
  BUY_NOW_CATALOG_FACETS: "Opus.BuyNowCatalogFacets",
  ORDER_SETUP_REQUESTS_ENABLED: "Opus.ToggledFeatures.OrderSetupRequestsEnabled",
  SUPPLIERS_RANK_SORTING_ENABLED: "Opus.SuppliersRankSortingEnabled",
  CHATBOT_CUSTOM_BUTTONS_ENABLED: "Opus.Analytics.ChatbotCustomButtonsEnabled",
};

const moduleSettings = computed(() => modulesSettings?.value?.find((el) => el.moduleId === MODULE_KEYS.ID));

export function useOpus() {
  return {
    newQuickConnectEnabled: computed(
      () => !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.NEW_QUICK_CONNECT_ENABLED)?.value,
    ),
    priceSortingEnabled: computed(
      () => !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.PRICE_SORTING_ENABLED)?.value,
    ),
    offersPreloadEnabled: computed(
      () => !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.OFFERS_PRELOAD_ENABLED)?.value,
    ),
    taxCertificatesStorageEnabled: computed(
      () =>
        !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.TAX_CERTIFICATES_STORAGE_ENABLED)?.value,
    ),
    partialOrderApprovalRequestsEnabled: computed(
      () =>
        !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.PARTIAL_ORDER_APPROVAL_REQUESTS_ENABLED)
          ?.value,
    ),
    previewEnabled: computed(
      () => !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.PREVIEW_ENABLED)?.value,
    ),
    buyNowCatalogFacets: computed<SettingsCatalogFacetType[]>(() => {
      let result: SettingsCatalogFacetType[] = [];

      const settingValue = moduleSettings.value?.settings?.find(
        (el) => el.name === MODULE_KEYS.BUY_NOW_CATALOG_FACETS,
      )?.value;

      if (settingValue && typeof settingValue === "string") {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const parsedStr = JSON.parse(settingValue);
          if (Array.isArray(parsedStr)) {
            result = parsedStr as SettingsCatalogFacetType[];
          }
        } catch {
          Logger.error(useOpus.name, "JSON.parse Opus.BuyNowCatalogFacets value error");
        }
      }

      return result.filter((item) => !!item.key && !!item.value);
    }),
    orderSetupRequestsEnabled: computed(
      () => !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.ORDER_SETUP_REQUESTS_ENABLED)?.value,
    ),
    suppliersRankSortingEnabled: computed(
      () =>
        !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.SUPPLIERS_RANK_SORTING_ENABLED)?.value,
    ),
    disabledTrackingDomains: computed<string[]>(() => {
      let trackingDomains: string[] = [];

      const disableTrackingDomains = moduleSettings.value?.settings?.find(
        (el) => el.name === MODULE_KEYS.DISABLE_TRACKING_DOMAINS,
      )?.value;

      if (disableTrackingDomains && typeof disableTrackingDomains === "string") {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const parsedStr = JSON.parse(disableTrackingDomains);
          if (Array.isArray(parsedStr)) {
            trackingDomains = parsedStr as string[];
          }
        } catch (e) {
          Logger.error(useOpus.name, "JSON.parse Opus.DisableTrackingDomains value error");
        }
      }

      return trackingDomains;
    }),
    chatbotCustomButtonsEnabled: computed(
      () =>
        !!moduleSettings.value?.settings?.find((el) => el.name === MODULE_KEYS.CHATBOT_CUSTOM_BUTTONS_ENABLED)?.value,
    ),
  };
}
