<template>
  <div class="op-about-us">
    <div
      class="flex min-h-[258px] items-center bg-[url('/static/images/opus/about-us/op-about-us-header-bg-mobile.webp')] bg-cover bg-no-repeat pl-6 pt-2 md:h-[380px] md:bg-[url('/static/images/opus/about-us/op-about-us-header-bg-desktop.webp')] md:pt-0"
    >
      <VcContainer class="bg-transparent">
        <div class="m-auto flex max-w-[1142px] flex-col">
          <h1 class="text-3xl font-light text-additional-50 md:text-[44px]">{{ $t("pages.about-us.header.title") }}</h1>
          <div
            class="op-about-us__subtitle align-center mt-4.5 flex min-h-[101px] items-center pl-5 text-4xl font-bold text-additional-50 md:mt-6 md:pl-10 md:text-[52px] md:leading-tight"
          >
            {{ $t("pages.about-us.header.subtitle") }}
          </div>
        </div>
      </VcContainer>
    </div>
    <VcContainer>
      <div class="content m-auto max-w-[1142px]">
        <div class="mb-14 flex flex-col gap-6 md:mb-8 md:mt-6 md:flex-row md:gap-9">
          <div class="md:flex-1">
            <p class="mb-3 text-3xl font-bold text-primary">
              {{ $t("pages.about-us.content.first_block.title") }}
            </p>
            <p class="mb-7 md:mb-5">{{ $t("pages.about-us.content.first_block.text_1") }}</p>
            <p class="mb-1 font-bold">{{ $t("pages.about-us.content.first_block.subtitle") }}</p>
            <p>{{ $t("pages.about-us.content.first_block.text_2") }}</p>
          </div>
          <div class="md:relative md:min-w-[360px]">
            <VcImage
              src="/static/images/opus/about-us/op-about-us-1.png"
              class="w-full md:absolute md:left-[20px] md:top-[35px] md:size-[323px] lg:top-[18px]"
              lazy
            />
          </div>
        </div>

        <div class="mb-10 flex flex-col gap-7 md:flex-row md:gap-2">
          <div class="md:flex-1">
            <p class="mb-3.5 text-3xl font-bold text-primary md:mb-5">
              {{ $t("pages.about-us.content.second_block.title") }}
            </p>
            <div v-for="i in 5" :key="i" class="mb-7 flex gap-4 leading-6 md:ml-8 md:gap-6">
              <VcIcon name="check-circle" size="sm" class="min-w-[20px] text-[color:var(--color-mobile-menu-icon)]" />
              <div>
                <p class="mb-1 font-bold">
                  {{ $t(`pages.about-us.content.second_block.list.block_${i}.title`) }}
                </p>
                <p>
                  {{ $t(`pages.about-us.content.second_block.list.block_${i}.text`) }}
                </p>
              </div>
            </div>
          </div>

          <div class="md:relative md:min-w-[340px]">
            <VcImage
              src="/static/images/opus/about-us/op-about-us-2.png"
              class="w-full md:absolute md:top-[200px] md:size-[323px] lg:top-[100px]"
              lazy
            />
          </div>
        </div>

        <div
          class="bg-[url('/static/images/opus/about-us/op-about-us-block-bg-mobile.webp')] bg-cover bg-no-repeat px-8 py-11 md:m-0 md:flex md:items-center md:gap-10 md:rounded-md md:bg-[url('/static/images/opus/about-us/op-about-us-block-bg-desktop.webp')] md:py-7"
        >
          <div class="w-full md:w-[43%]">
            <p class="mb-7 text-3xl font-bold text-additional-50 md:mb-1.5">
              {{ $t("pages.about-us.content.third_block.title") }}
            </p>
            <p class="mb-14 text-additional-50 md:mb-6">
              {{ $t("pages.about-us.content.third_block.text") }}
            </p>
          </div>

          <video
            class="w-full rounded-md object-cover md:w-[57%]"
            poster="/static/images/opus/about-us/op-about-us-video-preview.webp"
            controls
          >
            <source src="/static/images/opus/about-us/op-about-us.webm" type="video/webm" />
            <track kind="captions" srclang="en" label="english_captions" />
          </video>
        </div>

        <div class="mt-9 md:mb-20">
          <p class="mb-5 text-3xl font-bold text-primary md:mb-1.5">
            {{ $t("pages.about-us.content.fourth_block.title") }}
          </p>
          <div class="flex flex-col gap-7 md:flex-row md:items-center md:gap-[72px]">
            <p class="flex-1">
              {{ $t("pages.about-us.content.fourth_block.text") }}
            </p>
            <a href="https://www.omniapartners.com" target="_blank">
              <VcButton size="lg" class="w-full p-5 md:w-[333px]">
                {{ $t("pages.about-us.content.fourth_block.button") }}
              </VcButton>
            </a>
          </div>
        </div>
      </div>
    </VcContainer>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { usePageHead } from "@/core/composables";

const { t } = useI18n();
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("lg");

usePageHead({
  title: t("pages.about-us.meta.title"),
});
</script>

<style lang="scss">
.op-about-us {
  &__subtitle {
    border-left: 5px solid;
    border-color: #bbbbbb;
  }
}
</style>
