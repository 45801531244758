import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import getOrganizationOrdersQueryDocument from "./getOrganizationOrdersQuery.graphql";
import type { OpusCustomerOrderConnection, Query, QueryOrganizationOrdersArgs } from "@/core/api/graphql/types";

export async function getOrganizationOrders(
  payload?: QueryOrganizationOrdersArgs,
): Promise<OpusCustomerOrderConnection> {
  const { cultureName, organizationId } = globals;

  const { data } = await graphqlClient.query<Required<Pick<Query, "organizationOrders">>, QueryOrganizationOrdersArgs>({
    query: getOrganizationOrdersQueryDocument,
    variables: {
      cultureName,
      organizationId,
      ...payload,
    },
  });

  return data.organizationOrders;
}
