import { graphqlClient } from "@/core/api/graphql/client";
import { globals } from "@/core/globals";
import deleteSupplierTaxCertificateMutationDocument from "./opusDeleteSupplierTaxCertificate.graphql";
import type { Mutations, ResultType, MutationsDeleteSupplierTaxCertificateArgs } from "@/core/api/graphql/types";

export async function deleteSupplierTaxCertificate(
  taxCertificateId: string,
  supplierTaxCertificateId: string,
): Promise<ResultType> {
  const { userId } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "deleteSupplierTaxCertificate">>,
    MutationsDeleteSupplierTaxCertificateArgs
  >({
    mutation: deleteSupplierTaxCertificateMutationDocument,
    variables: {
      command: { userId, taxCertificateId, supplierTaxCertificateId },
    },
  });

  return data!.deleteSupplierTaxCertificate;
}
