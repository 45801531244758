<template>
  <div class="op-faq justify-center">
    <div class="z-10 flex flex-col">
      <div
        :class="{
          'bg-[url(/static/images/opus/faq/top-header-mobile.png)]': isMobile,
          'bg-[url(/static/images/opus/faq/top-header.png)]': !isMobile,
        }"
        class="z-10 flex justify-center bg-cover bg-no-repeat"
      >
        <div class="mx-auto mb-10 ml-10 mr-0 mt-6 flex w-full max-w-[1300px] flex-col md:mb-14 md:mt-11">
          <VcTypography tag="h1" variant="h1" class="font-light text-additional-50 md:text-4xl">
            {{ $t("pages.faq.header") }}
          </VcTypography>
          <div
            class="border--secondary-200 mt-6 flex max-w-60 items-center border-l-4 pl-6 md:mt-5 md:h-[80px] md:max-w-full md:pl-10"
          >
            <VcTypography tag="span" variant="h1" class="my-auto text-additional-50 md:text-5xl">
              {{ $t("pages.faq.subheader") }}
            </VcTypography>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-center">
      <div class="mx-auto flex w-full max-w-[1300px] flex-col scroll-smooth">
        <div
          id="stickyBlock"
          class="sticky top-[88px] z-[19] flex h-16 w-full flex-row items-center gap-4 overflow-auto whitespace-nowrap text-nowrap bg-additional-50 px-5 sm:gap-10 md:h-24 lg:top-[66px] lg:mb-2 xl:p-0"
        >
          <button
            v-for="(menuItem, index) in menuItems"
            :key="index"
            type="button"
            class="border-b border-dotted border-primary-600 font-bold text-primary md:text-2xl"
            @click="scrollTo(menuItem.id)"
          >
            {{ menuItem.text }}
          </button>
        </div>

        <div class="mb-16 flex flex-col gap-7 md:mb-48">
          <VcWidget v-for="item in faqContent" :key="item.id" :shadow="false" :border="false" size="md">
            <template #header-container>
              <span :id="item.title" class="vc-widget__header bg-primary">
                <VcTypography tag="h2" variant="h2" class="text-additional-50">
                  {{ item.title }}
                </VcTypography>
              </span>
            </template>

            <template #default-container>
              <div class="flex flex-col">
                <VcWidget
                  v-for="text in item.texts"
                  :key="text.id"
                  collapsible
                  collapsed
                  :shadow="false"
                  :title="text.content"
                  size="lg"
                  no-divide
                  class="rounded-none border-t-0 last:lg:rounded-b"
                >
                  <template #header-container="{ collapsed }">
                    <span class="vc-widget__header rounded-none" :class="{ 'bg-info-50': !collapsed }">
                      <span class="vc-widget__title">
                        <VcTypography tag="h3" variant="h4">
                          {{ text.content }}
                        </VcTypography>
                      </span>
                      <span class="vc-widget__prepend-append">
                        <VcIcon
                          :class="['vc-widget__append-icon', { 'vc-widget__append-icon--rotate': collapsed }]"
                          name="chevron-up"
                          size="sm"
                        />
                      </span>
                    </span>
                  </template>

                  <template #default-container>
                    <div class="vc-widget__slot bg-info-50">
                      <div v-html-safe="text.answer" />
                    </div>
                  </template>
                </VcWidget>
              </div>
            </template>
          </VcWidget>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePageHead } from "@/core/composables";
import { FAQ_CONTENT_PRIVATE_LIST, FAQ_CONTENT_PUBLIC_LIST } from "@/core/constants/opus";
import { useUser } from "@/shared/account/composables/useUser";

const { t } = useI18n();

usePageHead({
  title: t("pages.faq.meta.title"),
});

const { isPrivateSector } = useUser();

interface IFaqBlock {
  id: number;
  content: string;
  answer: string;
}

interface IFaqSection {
  id: number;
  title: string;
  texts: IFaqBlock[];
}

const faqContent = ref<IFaqSection[]>([]);
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");

const scrollTo = function (id: string) {
  const element = document.getElementById(id);
  if (element) {
    const elementPosition = element!.getBoundingClientRect().top;
    const currentScrollPosition = window.pageYOffset;
    const offset = elementPosition + currentScrollPosition;
    window.scrollTo({ top: offset - 160, behavior: "smooth" });
  }
};

const privateMenuItems = [
  {
    id: "General",
    text: t("pages.faq.private_sector.navigation_5"),
  },
  {
    id: "Browse & Research",
    text: t("pages.faq.private_sector.navigation_1"),
  },
  {
    id: "Account",
    text: t("pages.faq.private_sector.navigation_3"),
  },
];

const publicMenuItems = [
  {
    id: "General",
    text: t("pages.faq.public_sector.navigation_5"),
  },
  {
    id: "Browse & Research",
    text: t("pages.faq.public_sector.navigation_1"),
  },
  {
    id: "Orders",
    text: t("pages.faq.public_sector.navigation_2"),
  },
  {
    id: "Account",
    text: t("pages.faq.public_sector.navigation_3"),
  },
  {
    id: "Checkout",
    text: t("pages.faq.public_sector.navigation_4"),
  },
];

const menuItems = isPrivateSector.value ? privateMenuItems : publicMenuItems;

faqContent.value = isPrivateSector.value ? FAQ_CONTENT_PRIVATE_LIST : FAQ_CONTENT_PUBLIC_LIST;
</script>
