import { graphqlClient } from "@/core/api/graphql/client";
import { DEFAULT_PAGE_SIZE } from "@/core/constants";
import { globals } from "@/core/globals";
import { getFilterExpressionForCategorySubtree, getFilterExpressionForZeroPrice } from "@/core/utilities";
import searchResultsTotalQueryDocument from "./searchResultsTotalQuery.graphql";
import type { OpusProductConnection, Query, QueryProductsArgs } from "@/core/api/graphql/types";
import type { ProductsSearchParamsType } from "@/shared/catalog";

export async function searchResultsTotal(
  { categoryId, fuzzy, fuzzyLevel, filter }: Partial<ProductsSearchParamsType>,
  options: {
    /** @default false */
    withZeroPrice?: boolean;
  } = {},
): Promise<OpusProductConnection> {
  const { storeId, catalogId, userId, cultureName, currencyCode } = globals;
  const { withZeroPrice = false } = options;

  const filterString = [
    getFilterExpressionForCategorySubtree({ catalogId, categoryId }),
    getFilterExpressionForZeroPrice(withZeroPrice, currencyCode),
    filter,
  ]
    .filter(Boolean)
    .join(" ");

  const { data } = await graphqlClient.query<
    Required<Pick<Query, "products">>,
    QueryProductsArgs & { withFacets: boolean }
  >({
    query: searchResultsTotalQueryDocument,
    variables: {
      storeId,
      userId,
      cultureName,
      currencyCode,
      after: "0",
      first: 0,
      withFacets: true,
      fuzzy,
      fuzzyLevel,
      filter: filterString,
    },
  });

  return data.products;
}
